import React from "react"

import Layout from "./../layouts/Layout/Layout"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"
import ThreeColSection from "../sections/ThreeColSection/ThreeColSection"

import Background from "../assets/images/hero_contact.jpg"

const Contact = () => (
  <Layout>
    <HeroSubpage title="Kontakt" backgroundImage={Background} />
    <ThreeColSection
      heading="Akademia Alkantara"
      noBorder
      article1={
        "<p>Masz pytania dotyczące Akademii Alkantara?\n" +
        "Napisz do nas.</p>" +
        "<p><a href='mailto:kontakt@akademia-alkantara.pl'>kontakt@akademia-alkantara.pl</a></p>" +
        "<p>Akademia Alkantara to program organizowany przez Fundację Inspirujące Przykłady \n" +
        "(KRS 0000630893)</p>"
      }
      article2={
        "<p>Misją Fundacja Inspirujące Przykłady jest pokazywanie przykładów i inspirowanie młodych ludzi w inch wyborach życiowych. Od 2018 roku łączymy biznes, edukację i młodzież. Projekt Akademii Alkantara powstał we współpracy z pomorskimi przedsiębiorcami, którzy finansują ją swoimi pieniędzmi, talentem i czasem.</p>"
      }
    />
  </Layout>
)

export default Contact
